import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/personal-site/personal-site/src/components/Article.js";
import CodeSandbox from "components/CodeSandbox";
import { ISODate, LocaleDate } from "./helpers/components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Do you automatically reach for `}<a parentName="p" {...{
        "href": "https://momentjs.com"
      }}>{`Moment.js`}</a>{` when you need to format dates? Or
perhaps you prefer `}<a parentName="p" {...{
        "href": "https://date-fns.org"
      }}>{`date-fns`}</a>{`? But if you have basic formatting needs, don’t
discount the built-in JavaScript tools.`}</p>
    <h2 {...{
      "id": "use-case",
      "style": {
        "position": "relative"
      }
    }}>{`Use Case`}<a parentName="h2" {...{
        "href": "#use-case",
        "aria-label": "use case permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`I’m displaying a date with each article on this site. The source of the date is
an `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/ISO_8601"
      }}>{`ISO date string`}</a>{`, which looks like `}<ISODate mdxType="ISODate" />{`. This is a typical
format you’ll receive as part of an API response, for example.`}</p>
    <p>{`While the ISO format is explicit and great for cross-boundary data exchange,
it’s not display-friendly. Let’s show a localized date string instead.`}</p>
    <LocaleDate mdxType="LocaleDate" />
    <h2 {...{
      "id": "solution",
      "style": {
        "position": "relative"
      }
    }}>{`Solution`}<a parentName="h2" {...{
        "href": "#solution",
        "aria-label": "solution permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#Timestamp_string"
          }}>{`Parse the ISO date string`}</a></p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "js"
        }}><pre parentName="div" {...{
            "style": {
              "counterReset": "linenumber NaN"
            },
            "className": "language-js line-numbers"
          }}><code parentName="pre" {...{
              "className": "language-js"
            }}><span parentName="code" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` isoDateString `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"2020-07-10T14:16:40.463Z"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
`}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` date `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`new`}</span>{` `}<span parentName="code" {...{
                "className": "token class-name"
              }}>{`Date`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`isoDateString`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`;`}</span></code><span parentName="pre" {...{
              "aria-hidden": "true",
              "className": "line-numbers-rows",
              "style": {
                "whiteSpace": "normal",
                "width": "auto",
                "left": "0"
              }
            }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language"
          }}>{`Get the browser locale`}</a></p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "js"
        }}><pre parentName="div" {...{
            "style": {
              "counterReset": "linenumber NaN"
            },
            "className": "language-js line-numbers"
          }}><code parentName="pre" {...{
              "className": "language-js"
            }}><span parentName="code" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` locale `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`typeof`}</span>{` window `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`===`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"undefined"`}</span>{` `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`?`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"en"`}</span>{` `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`:`}</span>{` navigator`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`language`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`;`}</span></code><span parentName="pre" {...{
              "aria-hidden": "true",
              "className": "line-numbers-rows",
              "style": {
                "whiteSpace": "normal",
                "width": "auto",
                "left": "0"
              }
            }}><span parentName="span"></span></span></pre></div>
        <p parentName="li">{`If we’re not in a browser environment (`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`window === "undefined"`}</code>{`), default to
English.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString"
          }}>{`Format the date`}</a></p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "js"
        }}><pre parentName="div" {...{
            "style": {
              "counterReset": "linenumber NaN"
            },
            "className": "language-js line-numbers"
          }}><code parentName="pre" {...{
              "className": "language-js"
            }}><span parentName="code" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` formattedDate `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` date`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="code" {...{
                "className": "token function"
              }}>{`toLocaleDateString`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`locale`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
  year`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"numeric"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  month`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"long"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  day`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"numeric"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`;`}</span></code><span parentName="pre" {...{
              "aria-hidden": "true",
              "className": "line-numbers-rows",
              "style": {
                "whiteSpace": "normal",
                "width": "auto",
                "left": "0"
              }
            }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#Parameters"
          }}>{`See the `}<code parentName="a" {...{
              "className": "language-text"
            }}>{`Intl.DateTimeFormat()`}</code>{` constructor`}</a>{` for details on the
parameters.`}</p>
      </li>
    </ol>
    <p>{`It’s a bit more code than using one of the libraries, but your users will thank
you for the smaller bundle size.`}</p>
    <p>{`OK, they probably won’t—but you’ll have the satisfaction of knowing you’ve done
your part to improve their experience.`}</p>
    <CodeSandbox sandbox="tolocaledatestring-mn3ug" title="demo of toLocaleDateString" mdxType="CodeSandbox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      